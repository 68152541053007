import { render, staticRenderFns } from "./CloudcanteenPublicPrint.vue?vue&type=template&id=871381d4&"
import script from "./CloudcanteenPublicPrint.vue?vue&type=script&lang=js&"
export * from "./CloudcanteenPublicPrint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports