<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import ModalPrintLabelDialog from '@/components/dialog/ModalPrintLabelDialog'
import printJS from 'print-js'
import canteenOrderIdentifier from '@/mixins/canteenOrderIdentifier'

export default {
    components: {
        ModalPrintLabelDialog,
    },
    mixins: [canteenOrderIdentifier],
    data() {
        return {
            api: RestApiType.ORDERS_PUBLIC,
            dialog: false,
            content: null,
            orderId: null,
            pickupInfo: null,
            CcId: this.$route.params.CcId,
            restaurantId: this.$route.params.restaurantId,
            pickupTime: this.$route.query.pickupTime || null,
        }
    },
    computed: {
        orderIdentifier() {
            return this.getCanteenOrderIdentifier(
                this.$route.query.orderIdentifier
            )
        },
    },
    methods: {
        downloadLabel() {
            RestApiCollection.get(this.api)
                .downloadLabel(
                    this.$route.params.CcId,
                    this.$route.params.restaurantId,
                    this.$route.query.token
                )
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = this.orderIdentifier
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(console.error)
        },
        printLabel() {
            RestApiCollection.get(this.api)
                .downloadLabel(
                    this.$route.params.CcId,
                    this.$route.params.restaurantId,
                    this.$route.query.token
                )
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
    },
}
</script>
<template>
    <modal-print-label-dialog
        :value="true"
        :order-id="orderId"
        :pickup-info="pickupInfo"
        :title="$t('texts.print_item_labels')"
        :approve-text="$t('actions.back')"
        :cancel-text="$t('actions.leave')"
        @downloadLabels="downloadLabel"
        @printLabels="printLabel"
    >
        <div>Order #{{ orderIdentifier }}</div>
        <div>
            Pickup: {{ this.$date(pickupTime).format('D MMM YYYY, HH:mm') }}
        </div>
    </modal-print-label-dialog>
</template>
